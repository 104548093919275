import React from 'react';
import {AboutSection} from '../../styles/AboutUsStyles';


export default function AboutUs({ data, pageContext }) {
  return (
    <AboutSection id="about">
      <h2>{data.title[pageContext]}</h2>
      <p className={'subtitle'}>{data.subtitle[pageContext]}</p>
      <p className={'description'}>{data.description[pageContext]}</p>
    </AboutSection>
  );
}
