import React from 'react';
import localize from '../components/localize';
import HeroBanner from "../sections/homepage/HeroBanner";
import AboutUs from "../sections/homepage/AboutUs";
import OfferBlocks from "../sections/homepage/OfferBlocks";
import BenefitsTales from "../sections/homepage/BenefitsTales";
import Footer from "../components/Footer";
import Logos from "../sections/homepage/Logos";
import ContactForm from "../sections/contactPage/ContactForm";

function HomePage({ data, pageContext }) {

  
  return (
    <>
      <HeroBanner data={data.sanityHero} pageContext={pageContext.locale} />
      <AboutUs id='about' data={data.sanityAbout} pageContext={pageContext.locale}/>
      <div className="divider"></div>
      <OfferBlocks data={data.sanityOffer} pageContext={pageContext.locale}/>
      <div className="divider"></div>
      <BenefitsTales data={data.sanityBenefits} pageContext={pageContext.locale}/>
      <div className="divider"></div>
      <Logos data={data.sanityLogos} pageContext={pageContext.locale}/>
      <div className="divider"></div>
      <ContactForm data={data.sanityContactForm} pageContext={pageContext.locale} grid={'row'} />
      <Footer data={data.sanityFooter} pageContext={pageContext.locale}/>
    </>
  );
}


export default localize(HomePage);

export const query = graphql`
  query data {
    sanityHero {
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
      description {
        en
        fr
      }
      buttonText {
        en
        fr
      }
      image {
        asset {
          fluid(sizes: "(max-width: 2400px) 100vw, 2400px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityAbout {
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
      description {
        en
        fr
      }
    }
    sanityOffer {
      dev_title {
        fr
        en
      }
      copy_title {
        en
        fr
      }
      dev_image {
        asset {
          fixed(height: 550, width: 550) {
            ...GatsbySanityImageFixed
          }
        }
      }
      copy_image {
        asset {
          fixed(height: 550, width: 550) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    sanityBenefits {
      title {
        en
        fr
      }
      benefits {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
        image {
          asset {
            fixed(width: 120, height: 120) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
    sanityLogos {
      title {
        en
        fr
      }
      logoAndLink {
        link
        
        image {
          asset {
            metadata {
              dimensions {
                height
                width
              }
            }
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sanityContactForm {
      title {
        en
        fr
      }
      description {
          en
          fr
      }
      regulations {
        en {
          _rawChildren
        }
        fr {
          _rawChildren
        }
      }
    }
    sanityFooter {
      phone
      email
      address_line1
      address_line2
      social_media_list {
        website
        link
      }
      footer_nav_list {
        link
        title {
          en
          fr
        }
      }
    }
  }
 `;