import styled from 'styled-components';

export const LogosSection = styled.div`
  overflow: hidden;
  padding: 40px 50px 80px;
  @media (max-width: 1023px) {
    padding: 0 25px 40px ;
  }
  .slick-slider {
    margin: 80px 0;
    @media (max-width: 1023px) {
      margin: 40px 0  80px;
    }
    .slick-arrow {
      &.slick-disabled {
        display: none !important;
      }
    }
    
    .slick-list {
      .slick-track {
        @media (min-width: 1024px) {
          margin: 0;
          display: flex;
          align-items: center;
          width: 100% !important;
          gap: 10%;
        }
        &:before, &:after {
          display: none;
        }
      }
      .slick-slide {
        @media (min-width: 1024px) {
          height: fit-content;
          width: fit-content !important;
        }
        .gatsby-image-wrapper {
          @media (max-width: 1023px) {
          height: auto;
          width: 100% !important;
            margin-right: 40px;
          }
          img {
            @media (max-width: 1023px) {
              object-fit: contain !important;
            }
          }
        }
      }
    }
  }
`;

