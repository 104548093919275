import React from 'react';
import Img from 'gatsby-image';
import {Hero} from "../../styles/HeroBannerStyles";

export default function HeroBanner({ data, pageContext }) {
  const scrollToNextSection = () => {
    const hero = document.getElementById('hero');
    window.scrollTo(0, hero.scrollHeight);
  }
  return (
    <Hero id="hero">
      <div className="hero-text ">
        <span className="h5">{data.subtitle[pageContext]}</span>
        <h1>{data.title[pageContext]}</h1>
        <p>{data.description[pageContext]}</p>
        <button type="button" className={'button primary'} onClick={scrollToNextSection}>
          {data.buttonText[pageContext]}
        </button>
      </div>
      <Img className={'hero-image'} fluid={data.image.asset.fluid} loading={"eager"} alt="" fadeIn={false}/>
    </Hero>
  );
}
