import styled from 'styled-components';

export const Hero = styled.div`
  height: calc(100vh - 80px);
  background-color: var(--hero-overlay);
  
  .hero-text {
    position: absolute;
    left: 50px;
    bottom: 90px;
    color: var(--white);
    max-width: 50vw;
    
    @media (max-width: 1023px) {
      max-width: 80%;
      left: 25px;
      bottom: 50px;
    }
    
    h1 {
      margin: 0;
    }
    button {
      font-size: 24px;
      line-height: 32px; 
      font-weight: 600;
      padding: 24px 32px;
      margin-top: 45px;
      
      @media (max-width: 1023px) {
        font-size: 18px;
        line-height: 24px; 
        padding: 20px 16px;
        margin-top: 32px;
        background-color: var(--white);
        color: var(--blue-main);
        border: 1px solid var(--blue-dark);
        min-width: 230px;
        max-width: 50vw;
        &:hover {  
        color: var(--blue-hover-btn);  
        border-color: var(--blue-hover-btn);
        background-color: var(--white);
        }
      }
    }
  }
  .hero-image gatsby-image-wrapper {
    height: 100%;
  }
  .hero-image {
    position: relative;
    display: block;
    z-index: -1;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;